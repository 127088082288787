import React, { Component } from 'react'
import Layout from '../layout'
import TermsOfUse from '../components/TermsOfUse/TermsOfUse'

import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

class TermsOfUsage extends Component {
  render() {
    return (
      <Layout>
        <SEO title='Terms of Use' />
        <Header />
        <TermsOfUse />
        <Footer />
      </Layout>
    )
  }
}

export default TermsOfUsage
