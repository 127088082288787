import React from 'react'
import { Link } from 'gatsby'
import { Section, Container } from 'bloomer'

export default () => (
  <Section>
    <Container style={{ maxWidth: 800 }}>
      <div className="terms-wrapper">
        <h1 className="legal-heading">Terms of Use</h1>
        <p className="last-update">| Last Updated: August 12, 2019</p>
        <div className="terms-container legal-text">
          <p>
            Welcome to <Link to="/">www.sqrl.me</Link> , (the “App”) a mobile
            app that is owned by Eighty4, Inc, a Delaware public benefit
            corporation (the “Company”) which offers a platform that assists
            users in improving their financial skills and health by
            incentivizing saving with a healthy lifestyle. The App converts your
            daily steps (1 step = $ 0.01) into micro deposits ( at the end of
            each week) from your checking into your savings. Steps Data SQRL
            works by reading your steps / distance data from your Apple
            HealthKit and/or Google Fit data that is locally stored on your
            smartphone. We store your data and progress, so it is retrievable on
            multiple possible devices (especially important when we integrate
            wearables).
          </p>
          <p>
            This Agreement contains the complete terms and conditions that
            govern the use of the Company’s App. (intermittently,{' '}
            <strong>“Terms of Use”</strong>, or <strong>“Terms”</strong>). In
            these Terms of Use, the words <strong>“you”</strong> and{' '}
            <strong>“your”</strong>
            refer to each App registrant, visitor or user, <strong>“we”</strong>
            , <strong>“us”</strong> and <strong>“our”</strong>
            refers to the Company, and “Services” refers to all services
            provided by the Company through the App.
          </p>
          <p>
            By using the App, you agree to follow and be bound by these Terms of
            Use and the Privacy Policy, which is hereby incorporated into these
            Terms of Use.
          </p>
          <p>
            We may revise these Terms of Use at any time without notice to you.
            It is your responsibility to review these Terms of Use periodically.
            If at any time you find these Terms of Use unacceptable or if you do
            not agree to these Terms of Use, please do not <u>use</u> this App.
            If you have any questions about these Terms of Use, please contact
            us at service at{' '}
            <a href="mailto:support@eighty4.co">support@eighty4.co</a>.
          </p>
          <p>
            <span>
              <strong>1. SCOPE OF THESE TERMS AND THE APP</strong>
            </span>
            <span>
              These Terms of Use apply to your use of the App and to tools
              accessible through the App. We may have other applications or
              websites that may be covered by different Terms of Use. The Terms
              of Use for each of our applications are available on those other
              applications or websites. When you leave one of our sites or
              applications and go to another (whether controlled by us or by a
              third party), different terms may apply.
            </span>
            <span>
              The App is a resource presented for the sole purpose of assisting
              the public to improve their savings habits while simultaneously
              improving their health through increased physical activity. While
              we hope that you find the information helpful, you should remember
              that the content on the App is not meant to serve as a substitute
              for the advice of your own financial advisor or physician.
            </span>
          </p>
          <p>
            <span>
              <strong>2. APPLICABLE USE OF APP</strong>
            </span>
            <span>
              You may use this App only for purposes expressly permitted by
              these Terms. As a condition of your use of the App, you warrant to
              Company that you will not use the App for any purpose that is
              unlawful or prohibited by these terms, conditions, and notices.
            </span>
          </p>
          <p>
            <span>
              <strong>3. NO CO-BRANDING OR FRAMING.</strong> You may not use or
              authorize any party to co-brand or frame the App without the
              express prior written permission of an authorized representative
              of Company. For purposes of these Terms, "co-brand" means to
              display a name, logo, trademark, or other means of attribution or
              identification of any party in such a manner as is reasonably
              likely to give a user the impression that such other party has the
              right to display, publish, or distribute this App or content
              accessible within this App. For purposes of these Terms of Use,
              "frame" or “framing” refers to displaying any Company webpage
              within a bordered area of another application or website,
              regardless of whether the address of the originating Company
              webpage is visible. Furthermore, you agree to cease any
              unauthorized co-branding or framing immediately upon notice from
              Company.
            </span>
          </p>
          <p>
            <span>
              <strong>4. NO UNLAWFUL ACCESS.</strong> You agree that you will
              not use the App in any manner that could in any way disable,
              overburden, damage, or impair the App or otherwise interfere with
              any other party's use and enjoyment of the App. You further agree
              that you will not obtain, or attempt to obtain, any materials,
              content, or information by any means not expressly made available
              or provided for through authorized use of the App.
            </span>
          </p>
          <p>
            <span>
              <strong>5. YOUR INFORMATION; OUR NOTICES.</strong> When you open
              an account to subscribe to the App, you must provide complete and
              accurate information as requested on the registration template.
              You will also be asked to provide a user name and password. If you
              choose to register, you agree to: (a) supply the information
              requested in the registration process, (b) ensure that all the
              information you supply to us is accurate, and (c) update your
              personal information.
            </span>
            <span>
              Location service permissions are required on Android devices to
              accurately calculate the distance that you move, and we will ask
              you to provide permission for us to access your location. This
              permission will be constantly running in the background for as
              long as your access to the App isn’t terminated.
            </span>
            <span>
              The App uses the DWOLLA API for facilitating the transfer of money
              from the checking account that you identify to us in the
              registration template to the savings account that you also
              identify at the end of each week (ACH initiated each Monday for
              each preceding week). The Plaid API allows bank authentication
              instantly, so you can connect your checking & savings account to
              the App using your bank login information (username, password,
              security question).
            </span>
            <span>
              Both API partners work by providing us the API keys that are
              encrypted in-transit and and protected at rest. The App securely
              stores the encrypted API keys. However, we never store your
              financial information. Additionally, the Plaid API also provides
              us the ability to view balance in your checking account prior to
              moving funds so we can avoid creating NSF charges in your account.
              However, your <strong>bank</strong> balance information is never
              stored by the <strong>App</strong>.
            </span>
            <span>
              We also provide email notifications each time that you initiate an
              ACH transfer from your checking account to your savings account.
              These notifications are a core feature of our App, and therefore,
              you cannot turn them off. The email notifications are a
              requirement of one of our API partners.
            </span>
            <span>
              You are entirely responsible for the maintaining the
              confidentiality of all passwords issued to you when you register
              for the App. You may not use a third party’s account, user name or
              password at any time. You agree to notify the Company immediately
              of any unauthorized use of your account, user name or password.
              The Company shall not be liable for any losses you incur because
              of someone else’s use of your account or password, either with or
              without your knowledge. You may be held liable for any losses
              incurred by the Company our affiliates, officers, directors,
              employees, consultants, agents and representatives due to someone
              else’s use of your account or password.
            </span>
          </p>
          <p>
            <span>
              <strong>6. OWNERSHIP.</strong> The App is owned and operated by
              the Company. All right, title and interest in and to the App,
              software, data, and materials provided on this App, including but
              not limited to information, documents, logos, graphics, sounds and
              images (the
              <strong>“Materials”</strong>). Except as may be expressly stated
              on the App or in these Terms, no portion of the App, the Materials
              or the Content may be copied, reproduced, republished, downloaded
              uploaded, posted, displayed, transmitted or distributed in any
              way, and nothing in these Terms of Use or this App shall be
              construed to confer any license to you under any of the Company’s
              intellectual property rights, whether by estoppel, implication or
              otherwise. The Company does not sell, license, lease or otherwise
              provide any of the Materials other than as set forth herein. Any
              rights not expressly granted herein are reserved by the Company.
            </span>
          </p>
          <p>
            <span>
              <strong>7. LIMITED LICENSE TO ACCESS THE APP</strong>
            </span>
            <span>
              &nbsp;&nbsp;
              <strong>
                <u>7.1 Grant</u>
              </strong>
              . Company hereby grants you a limited, non-exclusive and
              non-transferable license, without right of sublicense, for the
              duration of these Terms to access and display the App on your
              Android or iOS mobile device and personal or desktop computer
              subject to the terms and conditions of this Agreement.
            </span>
            <span>
              &nbsp;&nbsp;
              <strong>
                <u>7.2 Restrictions</u>
              </strong>{' '}
              You may not edit, alter, abridge or otherwise change in any manner
              the Materials or content of the App, including, without
              limitation, all copyright and proprietary rights notices. For the
              sake of clarity, and without limitation, you may not, and may not
              permit others to:
            </span>
            <span>
              (1) reverse engineer, decompile, decode, decrypt, disassemble, or
              in any way derive source code from, the Materials or App;
            </span>
            <span>
              (2) distribute the App in whole or in part over the Internet, or
              via email or instant messaging, mobile device, wireless
              application protocol, short message service or radio system.
            </span>
            <span>
              (3) copy (other than one back-up copy), distribute, publicly
              display, transmit, sell, rent, lease or otherwise exploit the App
              or the App; or
            </span>
            <span>
              (4) distribute, sublicense, publish, broadcast, circulate rent,
              lease, loan, or grant access to the App to any third-party.
            </span>
          </p>
          <p>
            <span>
              <strong>8. USER CONTENT.</strong> At various locations on the App,
              the Company may permit you to post comments, messages and other
              content (the <strong>“User Content”</strong>). Contributions to,
              access to and use of the User Content is at your own risk and
              subject to the below terms and all other terms and conditions of
              these Terms of Use.
            </span>
            <span>
              You are legally and ethically responsible for any User Content -
              writings, files, pictures or any other work - that you post or
              transmit using the App or any service that allows interaction or
              dissemination of information. In posting User Content, you are
              responsible for honoring the rights of others, including
              intellectual-property rights (copyright, patent and trademark),
              the right to privacy and the right not to be libeled or slandered.
              For example, if you wish to post a copyrighted work as User
              Content, you are responsible for first obtaining the copyright
              holder’s permission.
            </span>
            <span>
              The use of User Content for any illegal activity is a violation of
              these Terms of Use. You agree not to use the App in any way that
              could damage, disable, or impair any services provided by the
              Company (or the network(s) connected to the App), violate the
              privacy and security of other users, or interfere with any user’s
              use and enjoyment of any of the services provided through the App.
              You agree not to attempt to gain unauthorized access to any
              services offered on the App, other accounts, computer systems or
              networks connected to the App, through hacking, password mining or
              any other means. You agree not to obtain or attempt to obtain any
              materials or information through any means not intentionally made
              available through the App.
            </span>
          </p>
          <p>
            <span>
              <strong>9. HYPERLINKING.</strong> The App may be hyperlinked to
              and by other applications or websites which are not maintained by,
              or related to the Company. Hyperlinks to such applications or
              websites are provided as a service to you and are not sponsored
              by, endorsed or otherwise affiliated with this App or the Company.
              The Company has not reviewed any or all such applications or sites
              and is not responsible for the content of any linking thereof and
              any links made directly from the App to another application or web
              page will be accessed at your own risk. Company makes no
              representations or warranties about the content, completeness,
              quality or accuracy of any such application or webpage.
            </span>
          </p>
          <p>
            <span>
              <strong>10. USE OF COMMUNICATION SERVICES.</strong> The App may
              intermittently contain forums, bulletin board services, chat
              areas, message boards, news feeds, news groups, communities,
              personal web pages, calendars, and/or other message or
              communication facilities designed to allow you to communicate with
              the Internet community or with a group (collectively,{' '}
              <strong>"Communications Services"</strong>). You agree to use the
              Communication Services only to post, send and receive messages and
              content that are considered proper and related to the
              Communication Service. Among other actions, when using a
              Communication Service, you agree that you will not post, send,
              submit, publish, or transmit in connection with this App, or cause
              to be posted, sent, submitted, published or transmitted, any
              material that:
            </span>
            <span>
              (i) you do not have the right to post, including any proprietary
              material of any third party protected by intellectual property
              laws (or by rights of privacy or publicity);
            </span>
            <span>
              (ii) advocates illegal activity, discusses an intent to commit an
              illegal act or violates any law;
            </span>
            <span>(iii) is vulgar, obscene, pornographic, or indecent;</span>
            <span>(iv) threatens or abuses others;</span>
            <span>(v) is libelous or defamatory towards others;</span>
            <span>
              (vi) is racist, abusive, harassing, threatening or offensive;
            </span>
            <span>
              (vii) seeks to exploit or harm children by exposing them to
              inappropriate content, or asking for personally identifiable
              details or information;
            </span>
            <span>
              (viii) harvests or otherwise collects information about others,
              including e-mail addresses, without their consent;
            </span>
            <span>
              (ix) impersonates or misrepresents your connection to any other
              entity or person or otherwise manipulates or forges headers or
              identifiers to disguise the origin of content;
            </span>
            <span>
              (x) falsifies or deletes any author attributions, legal or other
              proper notices or proprietary designations or labels of the origin
              or source of software or other material contained in a file that
              is permissibly uploaded (e.g., copyright, trademark or patent
              notices);
            </span>
            <span>
              (xi) advertises any commercial endeavor (e.g., offering for sale
              products or services) or otherwise engages in any commercial
              activity (e.g., conducting raffles or contests, displaying
              sponsorship banners, and/or soliciting goods or services) except
              as may be specifically authorized on this App;
            </span>
            <span>
              (xii) solicits funds, advertisers or sponsors for any purpose;
            </span>
            <span>
              (xiii) includes programs that contain viruses, worms and/or Trojan
              horses or any other computer code, files or programs designed to
              interrupt, destroy or limit the functionality of any computer
              software or hardware or telecommunications device;
            </span>
            <span>
              (xiv) disrupts the normal flow of dialogue, causes a screen to
              scroll faster than other users are able to type, or otherwise act
              in a way which affects the ability of other people to engage in
              real-time activities via this App;
            </span>
            <span>
              (xv) amounts to a pyramid or other like scheme, including
              contests, chain letters, and surveys;
            </span>
            <span>
              (xvi) disobeys any policy or regulations including any code of
              conduct or other guidelines, established from time to time
              regarding use of this App or any networks connected to this App;
              or
            </span>
            <span>
              (xvii) contains hyperlinks to other Apps that contain content that
              falls within the scope of this Section.
            </span>
            <span>
              You acknowledge that any materials uploaded to the Communication
              Service may be subject to posted limits on use, reproduction
              and/or dissemination and you are responsible for abiding by such
              limitations with respect to your submissions, including any
              downloaded materials. Notwithstanding these rights, you remain
              solely responsible for the content of your submissions. You
              acknowledge and agree that neither Company nor any third party
              that provides Content to Company will assume or have any liability
              for any action made by Company or such third party with respect to
              any submission.
            </span>
            <span>
              You acknowledge that the App may or may not pre-screen materials
              uploaded to the Communication Service but that the App and its
              designees shall have the right, but not the obligation, in its
              sole discretion to pre-screen, refuse, remove, or delete any
              Content that violates this Agreement or is otherwise objectionable
              as determined by the App in its sole discretion.
            </span>
            <span>
              The App reserves the right at any time and from time to time to
              modify or discontinue, temporarily or permanently, the
              Communication Services (or any part thereof) with or without
              notice. You agree that the App will not be liable to you or any
              third party for any modification, suspension or discontinuance of
              the Communication Services.
            </span>
            <span>
              WHILE THE APP EXPLICITLY PROHIBITS THE ABOVE CONDUCT, YOU
              ACKNOWLEDGE AND AGREE THAT YOU MAY BE EXPOSED TO SUCH CONDUCT AND
              CONTENT AND THAT YOUR USE OF THE APP IS ENTIRELY AT YOUR OWN RISK,
              AND THAT THE APP SHALL HAVE NO LIABILITY OF ANY KIND FOR SUCH
              CONDUCT.
            </span>
            <span>
              YOU UNDERSTAND AND AGREE THAT IF YOUR USE OF COMMUNICATIONS
              SERVICES VIOLATES ANY OF THE ABOVE CONDUCT, WE MAY TERMINATE YOUR
              USE OF THE APP IMMEDIATELY WITHOUT PRIOR NOTICE AND WITHOUT ANY
              RIGHT OF REFUND, SET-OFF OR A HEARING.]
            </span>
          </p>
          <p>
            <span>
              <strong>11. RIGHT TO TERMINATE ACCESS.</strong> Company reserves
              the right to monitor your use of this App to determine compliance
              with these Terms of Use, as well as the right to edit, refuse to
              post or remove any information or materials, in whole or in part,
              at its sole discretion. Company reserves the right to terminate
              your access to the App, App or any of the Communication Services
              at any time without notice if you violate these Terms of Use.
            </span>
          </p>
          <p>
            <span>
              <strong>12. DISCLOSURE UNDER LAW.</strong> Company reserves the
              right always to disclose any information as necessary to satisfy
              any applicable law, regulation, legal process or governmental
              request.
            </span>
          </p>
          <p>
            <span>
              <strong>13. DISCLAIMER & LIMITATIONS ON LIABILITY.</strong>
            </span>
            <span>
              YOUR USE OF THIS APP IS AT YOUR OWN RISK. THE APP AND ALL CONTENT
              THEREIN IS PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND,
              EITHER EXPRESSED OR IMPLIED. COMPANY DISCLAIMS ALL WARRANTIES,
              INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PURPOSE, TITLE, OR NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THAT
              THE FUNCTIONS OF THE APP WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
              DEFECTS WILL BE CORRECTED, OR THAT THIS APP OR THE SERVER THAT
              MAKES THE APP AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS. COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATION
              REGARDING USE, OR THE RESULT OF USE, OF ANY CONTENT ON THE APP OR
              APP IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. YOU
              ACKNOWLEDGE THAT THE APP MAY INCLUDE TECHNICAL INACCURACIES OR
              TYPOGRAPHICAL ERRORS, AND THAT THE COMPANY MAY MAKE CHANGES OR
              IMPROVEMENTS AT ANY TIME. YOU ASSUME THE ENTIRE COST OF ALL
              NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS
              OR DAMAGE ARISING FROM THE USE OF THIS APP OR ITS CONTENT. COMPANY
              MAKES NO WARRANTIES THAT YOUR USE OF THE APP OR APP WILL NOT
              INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO LIABILITY OR
              RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.
            </span>
            <span>
              NEITHER THE COMPANY, ITS SUBSIDIARIES, AFFILIATES, LICENSORS,
              SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS,
              AND DIRECTORS WILL BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT,
              PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER
              DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING,
              EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF COMPANY HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES WERE
              REASONABLY FORSEEABLE.
            </span>
            <span>
              IN NO EVENT WILL THE COLLECTIVE LIABILITY THE COMPANY AND ITS
              SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT
              PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, REGARDLESS
              OF THE FORM OF ACTION (WHETHER IN CONTRACT, TORT, OR OTHERWISE),
              EXCEED THE GREATER OF $1,000.00 OR THE AMOUNT YOU HAVE PAID TO
              COMPANY FOR THE APPLICABLE CONTENT OR SERVICE OUT OF WHICH
              LIABILITY AROSE.
            </span>
          </p>
          <p>
            <span>
              <strong>14. INDEMNITY.</strong> YOU AGREE TO INDEMNIFY AND HOLD
              THE COMPANY, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, CONTENT
              PROVIDERS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS,
              DIRECTORS, AND CONTRACTORS (THE “INDEMNIFIED PARTIES”) HARMLESS
              FROM ANY BREACH OF THESE TERMS OF USE BY YOU, INCLUDING ANY USE OF
              CONTENT OTHER THAN AS EXPRESSLY AUTHORIZED IN THESE TERMS OF USE.
              YOU AGREE THAT THE INDEMNIFIED PARTIES WILL HAVE NO LIABILITY IN
              CONNECTION WITH ANY SUCH BREACH OR UNAUTHORIZED USE, AND YOU AGREE
              TO INDEMNIFY ANY AND ALL RESULTING LOSS, DAMAGES, JUDGMENTS,
              AWARDS, COSTS, EXPENSES, AND ATTORNEYS' FEES OF THE INDEMNIFIED
              PARTIES IN CONNECTION THEREWITH. YOU WILL ALSO INDEMNIFY AND HOLD
              THE INDEMNIFIED PARTIES HARMLESS FROM AND AGAINST ANY CLAIMS
              BROUGHT BY THIRD PARTIES ARISING OUT OF YOUR USE OF THE
              INFORMATION ACCESSED FROM THIS APP.
            </span>
          </p>
          <p>
            <span>
              <strong>15. TRADEMARKS AND COPYRIGHTS.</strong> Trademarks,
              service marks, logos, and copyrighted works (hereinafter,{' '}
              <strong>"Intellectual Property"</strong>) appearing in the App are
              the property of THE Company or the party that provided the
              Intellectual Property to the Company. The Company and any party
              that provides Intellectual Property to Company retain all rights
              with respect to any of their respective Intellectual Property
              appearing in this App.
            </span>
          </p>
          <p>
            <span>
              <strong>16. COPYRIGHT INFRINGEMENT.</strong> If you believe that
              any User Content to which you claim rights under the United States
              Copyright Act has been copied in a way that constitutes copyright
              infringement, please provide to the Company's the written
              information specified below at the indicated email address. Please
              note that this procedure is exclusively for notifying Company and
              its affiliates that your copyrighted material has been infringed.
              Please include the following:
            </span>
            <span>
              &nbsp;&nbsp;— An electronic or physical signature of the person
              authorized to act on your behalf or on behalf of the owner of the
              copyright interest;
            </span>
            <span>
              &nbsp;&nbsp;— A description of the copyrighted work that you claim
              has been infringed ;
            </span>
            <span>
              &nbsp;&nbsp;— A description of where the material that you claim
              is infringing is located on the App;
            </span>
            <span>
              &nbsp;&nbsp;— Your address, telephone number, and e-mail address;
            </span>
            <span>
              &nbsp;&nbsp;— A statement by you that you have a good-faith belief
              that the disputed use is unauthorized by the copyright owner, its
              agent, or the law;
            </span>
            <span>
              &nbsp;&nbsp;— A statement by you, made under penalty of perjury,
              that the above information in your notice is accurate and that you
              are the copyright owner or authorized to act on the copyright
              owner's behalf.
            </span>
          </p>
          <p>
            <span>
              <strong>17. SECURITY.</strong> Any passwords used for this App are
              for your use only. You will be responsible for the security of
              your password(s). From time to time, the Company may require that
              you change your password. You are prohibited from using any
              services or facilities provided with respect to this App to
              compromise the security or tamper with the App system resources
              and/or the accounts of other App users. The use or distribution of
              tools designed for compromising security (e.g., password crackers,
              rootkits, Trojan horses, or network probing tools) is strictly
              prohibited. If you become involved in any violation of system
              security, Company reserves the right to release your account
              details to system administrators at other websites and/or legal
              authorities in order to assist them in resolving security
              incidents. Company reserves the right to investigate suspected
              violations of these Terms of Use. Company further reserves the
              right to fully cooperate with any law enforcement authorities or
              court order requesting or direction Company to disclose the
              identity of anyone posting any e-mail messages, or publishing or
              otherwise making available any materials that are believed to
              violate these Terms of Use.
            </span>
            <span>
              BY ACCEPTING THIS AGREEMENT, YOU WAIVE ALL RIGHTS AND AGREE TO
              HOLD COMPANY HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION
              TAKEN BY COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS
              AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS
              BY EITHER COMPANY OR LAW ENFORCEMENT AUTHORITIES ARISING FROM YOUR
              BREACH OF THESE TERMS OF USE.
            </span>
          </p>
          <p>
            <span>
              <strong>18. DISPUTES.</strong> If there is any dispute about or
              involving this Terms of Use, the App and/or any Communications
              Services, you agree that any dispute shall be governed by the laws
              of the State of Virginia, notwithstanding any principles of
              conflicts of law. You specifically consent to personal and
              exclusive jurisdiction by and venue in the state and federal
              courts sitting in Alexandria, Virginia about any dispute between
              you and Company arising out of or involving this Agreement, the
              App, and/or any Communications Services.
            </span>
          </p>
          <p>
            <strong>19. MISCELLANEOUS</strong>
          </p>
          <ol type="A" style={{ marginLeft: 20 }}>
            <li>
              If any part of these Terms of Use is found by a court of competent
              jurisdiction to be unlawful, void or unenforceable, that part will
              be deemed severable and will not affect the validity and
              enforceability of any remaining provisions.
            </li>
            <li>
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and Company as a result of
              this Agreement or your use of Company's App.
            </li>
            <li>
              These Terms of Use constitute the entire agreement between you and
              the Company relating to the subject matter hereof, and supersedes
              all prior or contemporaneous communications and proposals, whether
              electronic, oral or written between you and Company with respect
              to the App. Notwithstanding the foregoing, you may also be subject
              to additional terms and conditions, posted policies (including but
              not limited to the Privacy Policy), guidelines, or rules that may
              apply when you use the App . Company may revise these Terms of Use
              at any time by updating this posting. You should review the
              Agreement from time to time to determine if any changes have been
              made to the Agreement. Your continued use of the App after any
              changes have been made to this Agreement signifies and confirms
              your acceptance of any changes or amendments to this Agreement.
            </li>
            <li>
              The failure of Company to exercise or enforce any right or
              provision of this Agreement shall not operate as a waiver of such
              right or provision. Any waiver of this Agreement by Company must
              be in writing and signed by an authorized representative of the
              Company.
            </li>
          </ol>
        </div>
      </div>
    </Container>
  </Section>
)
